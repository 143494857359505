import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("trix-initialize", () => {
      const toolbarId = this.element.getAttribute("toolbar")
      const toolbar = document.querySelector(`#${toolbarId}`)
      toolbar.querySelector(".trix-button-group--file-tools").remove()
    })

    // remove file upload handling
    this.element.addEventListener("trix-file-accept", (event) => {
      event.preventDefault()
    })
  }
}
